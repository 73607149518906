import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout/layout";
import {
  enrichStoryWithGatsbyImages,
  getBlok,
} from "../components/utils/storyblok";
import Seo from "../components/layout/seo";
import MinicardLive from "../components/layout/minicard-live";
import {
  getPageSlug,
  loc2liveSlug,
  prefixUrl,
  suffixTitle,
} from "../components/utils/no-import-commons";

const Live = ({ data, pageContext }) => {
  if (
    "content" in data?.sharedContent &&
    typeof data.sharedContent.content === "string"
  ) {
    data.sharedContent.content = JSON.parse(data.sharedContent.content);
  }
  const sharedContent = data?.sharedContent?.content ?? {};
  const allLives = data.allLives.nodes.map((n) =>
    JSON.parse(n?.content ?? "{}")
  );
  enrichStoryWithGatsbyImages(sharedContent, data?.images?.nodes ?? [], true);
  allLives.forEach((live) => {
    enrichStoryWithGatsbyImages(live, data?.images?.nodes ?? [], true);
  });

  console.log(allLives, sharedContent);

  return (
    <Layout lm={pageContext.lm}>
      {(sharedContent?.list_header_bloks ?? []).map(getBlok)}
      {(allLives ?? []).map((e, i) => (
        <MinicardLive b={e} key={e._uid + i} />
      ))}
      {pageContext?.totalPages > 1 && (
        <div className="bg-white py-6 flex justify-center gap-4">
          {[...Array(pageContext.totalPages).keys()].map((i) => (
            <Link
              to={`${loc2liveSlug[pageContext.lang]}/${i > 0 ? i + 1 : ""}`}
              key={i}
              className={`grid place-content-center w-6 h-6 text-xs border border-black rounded-full ${
                pageContext.page / pageContext.livesPerPage === i
                  ? "pag-active bg-black text-white"
                  : "text-black"
              }`}
            >
              {i + 1}
            </Link>
          ))}
        </div>
      )}
      {(sharedContent?.shared_footer_bloks ?? []).map(getBlok)}
    </Layout>
  );
};

export const Head = ({ data, pageContext }) => {
  const { currentPage, lang, totalPages } = pageContext;
  //this assumes {seoInfo.url} ends with / so {page} without /
  const page = currentPage > 1 ? `${currentPage}` : "";
  const story = data.sharedContent;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  const seoInfo = story?.content?.page_information[0];
  seoInfo.url = prefixUrl(getPageSlug(seoInfo.slug + page, lang));
  seoInfo.title = suffixTitle(seoInfo?.title ?? "");
  const lm = { ...(pageContext?.lm ?? {}) };
  //{page} without /
  for (const k in lm) {
    lm[k] = prefixUrl(lm[k]) + "/" + page;
  }
  const urlWithoutPage = prefixUrl(getPageSlug(seoInfo.slug, lang));

  return (
    <Seo info={seoInfo} lm={lm}>
      {totalPages > 1 && (
        <>
          {currentPage < totalPages && (
            <link rel="next" href={`${urlWithoutPage}${currentPage + 1}`} />
          )}
          {currentPage > 1 && (
            <link
              rel="prev"
              href={`${urlWithoutPage}${
                currentPage !== 2 ? currentPage - 1 : ""
              }`}
            />
          )}
        </>
      )}
    </Seo>
  );
};

export const query = graphql`
  query ($images: [String]!, $lang: String!, $page: Int!, $livesPerPage: Int!) {
    images: allFile(filter: { base: { in: $images } }) {
      nodes {
        base
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
    allLives: allStoryblokEntry(
      limit: $livesPerPage
      skip: $page
      filter: { field_component: { eq: "Registrazione" }, lang: { eq: $lang } }
    ) {
      nodes {
        content
      }
    }
    sharedContent: storyblokEntry(
      slug: { eq: "live-academy-shared-content" }
      lang: { eq: $lang }
    ) {
      content
    }
  }
`;

export default Live;
