import React, { useContext } from "react";
import { LanguageContext } from "../context/language-context";
import ImageComponent from "../utils/image";
import {
  getColor,
  getColorClass,
  getTextColorClass,
  GReactMD,
  PageOrUrl,
} from "../utils/storyblok";
import playWhite from "../../resources/assets/play-white.png";

const MinicardLive = ({ b }) => {
  const { t, locale } = useContext(LanguageContext);
  const slug =
    locale === "it"
      ? `/live-academy${b?.page_information?.[0]?.slug}`
      : `/${locale}/live-academy${b?.page_information?.[0]?.slug}`;

  return (
    <section style={{ backgroundColor: getColor(b?.color) }}>
      <div className="flex flex-col lg:grid lg:grid-cols-2">
        <div className="h-[50vh] lg:h-auto relative">
          <div className="grayscale mix-blend-multiply absolute w-full h-full">
            <PageOrUrl slug={slug}>
              <ImageComponent img={b?.image?.[0]} />
              <div className="grid place-content-center absolute z-20 w-full h-full left-0 top-0">
                <img
                  src={playWhite}
                  alt={t.play_video}
                  className="w-16 lg:w-[92px]"
                />
              </div>
            </PageOrUrl>
          </div>
        </div>
        <div className="lg:min-h-[310px] lg:max-w-screen-md w-full lg:ml-auto lg:mr-0 lg:-order-1 lg:pl-16 p-5 flex flex-col gap-4">
          <h2 className="mud-h3-nolg">{b?.name}</h2>
          <GReactMD>{b?.abstract}</GReactMD>
          <div className="flexc h-full">
            <PageOrUrl slug={slug}>
              <span
                className={`btn border-${getTextColorClass(
                  b?.color
                )} hover:bg-${getTextColorClass(
                  b?.color
                )} hover:text-${getColorClass(b?.color)}`}
              >
                {t.watch_video}
              </span>
            </PageOrUrl>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MinicardLive;
